export default [
  {
    path: '/',
    name: 'front.convo',
    component: () => import('@/views/Home.vue'),
    meta: {
      pageTitle: 'Trial version convo',
      breadcrumb: [
        {
          text: 'Convo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/login',
    name: 'front.login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'public',
    },
  },
  {
    path: '/peer-ok',
    name: 'front.login',
    component: () => import('@/views/PeerOk.vue'),
    meta: {
      layout: 'public',
    },
  },
  {
    path: '/register',
    name: 'front.register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/register-ok',
    name: 'front.register-ok',
    component: () => import('@/views/registerApplicant/RegisterOk.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/confirmed-email',
    name: 'confirmed-email',
    component: () => import('@/views/registerApplicant/ConfirmedEmail.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/reset-password-intranet',
    name: 'reset-password-intranet',
    component: () => import('@/views/registerApplicant/ResetPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/reset-password-intranet/sended',
    name: 'reset-password-sended-intranet',
    component: () => import('@/views/registerApplicant/EmailRestartPasswordSended.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/registerApplicant/ResetPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/reset-password/sended',
    name: 'reset-password-sended',
    component: () => import('@/views/registerApplicant/EmailRestartPasswordSended.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/reset-password/generate',
    name: 'reset-password-generate',
    component: () => import('@/views/registerApplicant/CreateNewPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/reset-password-ok',
    name: 'reset-password-ok',
    component: () => import('@/views/registerApplicant/ResetPasswordOk.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  { // Peers
    path: '/peers',
    component: () => import('@/layouts/public/PublicLayout.vue'),
    meta: {
      layout: 'public',
      pageTitle: 'Admin section',
      breadcrumb: [
        {
          text: 'Convo',
          active: true,
        },
      ],
    },
    children: [
      {
        path: ':status',
        name: 'front.peers',
        component: () => import('@/views/peers/Index.vue'),
        meta: {
          layout: 'public',
          pageTitle: 'Trial version convo',
          breadcrumb: [
            {
              text: 'Convo',
              active: true,
            },
          ],
        },
      },
    ],
  },
  { // Senior call
    path: '/senior-call',
    component: () => import('@/layouts/public/PublicLayout.vue'),
    meta: {
      layout: 'public',
      pageTitle: 'Admin section',
      breadcrumb: [
        {
          text: 'Convo',
          active: true,
        },
      ],
    },
    children: [
      {
        path: '/',
        name: 'front.senior-call',
        component: () => import('@/views/convos/Convo.vue'),
        meta: {
          layout: 'public',
          pageTitle: 'Trial version convo',
          breadcrumb: [
            {
              text: 'Convo',
              active: true,
            },
          ],
        },
      },
      {
        path: '/senior-call/sent',
        name: 'front.senior-call-sent',
        component: () => import('@/views/convos/ConvoSent.vue'),
        meta: {
          layout: 'full',
          pageTitle: 'Trial version convo',
          breadcrumb: [
            {
              text: 'Convo',
              active: true,
            },
          ],
        },
      },
      {
        path: ':convo_id/section/:id',
        name: 'front.senior-call-section',
        component: () => import('@/views/convos/ConvoSection.vue'),
        meta: {
          layout: 'public',
          pageTitle: 'Trial version convo',
          breadcrumb: [
            {
              text: 'Convo',
              active: true,
            },
          ],
        },
      },
      {
        path: 'preview',
        name: 'front.senior-call-preview',
        component: () => import('@/views/convos/Preview.vue'),
        meta: {
          layout: 'public',
          pageTitle: 'Trial version convo',
          breadcrumb: [
            {
              text: 'Convo',
              active: true,
            },
          ],
        },
      },
      {
        path: 'submit-institution/:payload',
        name: 'front.senior-call-upload-institution',
        component: () => import('@/views/convos/VerifyInstitution.vue'),
        meta: {
          layout: 'public',
          pageTitle: 'Trial version convo',
          breadcrumb: [
            {
              text: 'Convo',
              active: true,
            },
          ],
        },
      },
      {
        path: 'submit-file/:payload',
        name: 'front.senior-call-upload-parameter-file',
        component: () => import('@/views/convos/UploadFile.vue'),
        meta: {
          layout: 'public',
          pageTitle: 'Trial version convo',
          breadcrumb: [
            {
              text: 'Convo',
              active: true,
            },
          ],
        },
      },
    ],
  },
  { // Academy call
    path: '/academy-call',
    name: 'academy-call',
    component: () => import('@/layouts/public/PublicLayout.vue'),
    meta: {
      layout: 'public',
      pageTitle: 'Admin section',
      breadcrumb: [
        {
          text: 'Convo',
          active: true,
        },
      ],
    },
    children: [
      {
        path: '/',
        name: 'front.academy-call',
        component: () => import('@/views/convos/Convo.vue'),
        meta: {
          layout: 'public',
          pageTitle: 'Trial version convo',
          breadcrumb: [
            {
              text: 'Convo',
              active: true,
            },
          ],
        },
      },
      {
        path: ':convo_id/section/:id',
        name: 'front.academy-call-section',
        component: () => import('@/views/convos/ConvoSection.vue'),
        meta: {
          layout: 'public',
          pageTitle: 'Trial version convo',
          breadcrumb: [
            {
              text: 'Convo',
              active: true,
            },
          ],
        },
      },
      {
        path: 'preview',
        name: 'front.academy-call-preview',
        component: () => import('@/views/convos/Preview.vue'),
        meta: {
          layout: 'public',
          pageTitle: 'Trial version convo',
          breadcrumb: [
            {
              text: 'Convo',
              active: true,
            },
          ],
        },
      },
      {
        path: 'submit-institution/:payload',
        name: 'front.academy-call-upload-institution',
        component: () => import('@/views/convos/VerifyInstitution.vue'),
        meta: {
          layout: 'public',
          pageTitle: 'Trial version convo',
          breadcrumb: [
            {
              text: 'Convo',
              active: true,
            },
          ],
        },
      },
      {
        path: 'submit-file/:payload',
        name: 'front.academy-call-upload-parameter-file',
        component: () => import('@/views/convos/UploadFile.vue'),
        meta: {
          layout: 'public',
          pageTitle: 'Trial version convo',
          breadcrumb: [
            {
              text: 'Convo',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/academy-call/sent',
    name: 'front.academy-call-sent',
    component: () => import('@/views/convos/ConvoSent.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Trial version convo',
      breadcrumb: [
        {
          text: 'Convo',
          active: true,
        },
      ],
    },
  },
  // Intranet part
  {
    path: '/intranet',
    component: () => import('@/layouts/admin/AdminLayout.vue'),
    meta: {
      layout: 'admin',
      pageTitle: 'Admin section',
      breadcrumb: [
        {
          text: 'Convo',
          active: true,
        },
      ],
      middleware: 'check-user-admin',
    },
    children: [
      {
        path: '/',
        name: 'admin.login',
        component: () => import('@/views/back/admin/login/Index.vue'),
      },
      { // Alerts
        path: 'alerts',
        name: 'alerts',
        component: () => import('@/views/back/admin/alerts/Index.vue'),
      },
      { // Favorites
        path: 'favorites',
        name: 'favorites',
        component: () => import('@/views/back/partials/components/FavoritesTable.vue'),
      },
      { // Knowledge Base
        path: 'knowledge-base',
        component: () => import('@/layouts/admin/RouterView.vue'),
        children: [
          {
            path: '/',
            name: 'knowledge-base.index',
            component: () => import('@/views/back/admin/knowledge-base/Index.vue'),
          },
          {
            path: 'new',
            name: 'knowledge-base.create',
            component: () => import('@/views/back/admin/knowledge-base/Edit.vue'),
          },
          {
            path: 'edit/:id',
            name: 'knowledge-base.edit',
            component: () => import('@/views/back/admin/knowledge-base/Edit.vue'),
          },
          {
            path: 'show/:id',
            name: 'knowledge-base.show',
            component: () => import('@/views/back/admin/knowledge-base/Chapter.vue'),
          },
        ],
      },
      { // Admin zone
        path: 'admin',
        component: () => import('@/layouts/admin/RouterView.vue'),
        children: [
          { // Dashboard
            path: 'dashboard',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.dashboard',
                component: () => import('@/views/back/admin/dashboard/Index.vue'),
              },
            ],
          },
          { // Senior calls
            path: 'senior-calls',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.calls.senior.index',
                component: () => import('@/views/back/admin/senior-call/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.calls.senior.create',
                component: () => import('@/views/back/admin/senior-call/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.calls.senior.edit',
                component: () => import('@/views/back/admin/senior-call/Edit.vue'),
              },
              {
                path: 'view/:id',
                name: 'admin.calls.senior.view',
                component: () => import('@/views/back/admin/senior-call/View.vue'),
              },
              {
                path: 'groups/:id',
                name: 'admin.calls.senior.groups',
                component: () => import('@/views/back/admin/senior-call/Groups.vue'),
              },
              {
                path: 'pending/:id',
                name: 'admin.calls.senior.pending',
                component: () => import('@/views/back/admin/senior-call/Pendings.vue'),
              },
            ],
          },
          { // Academy calls
            path: 'academy-calls',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.calls.academy.index',
                component: () => import('@/views/back/admin/academy-call/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.calls.academy.create',
                component: () => import('@/views/back/admin/academy-call/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.calls.academy.edit',
                component: () => import('@/views/back/admin/academy-call/Edit.vue'),
              },
              {
                path: 'view/:id',
                name: 'admin.calls.academy.view',
                component: () => import('@/views/back/admin/academy-call/View.vue'),
              },
              {
                path: 'groups/:id',
                name: 'admin.calls.academy.groups',
                component: () => import('@/views/back/admin/academy-call/Groups.vue'),
              },
              {
                path: 'pending/:id',
                name: 'admin.calls.academy.pending',
                component: () => import('@/views/back/admin/academy-call/Pendings.vue'),
              },
            ],
          },
          { // Academy awarded
            path: 'academy-awardees',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.calls.academy_awarded.index',
                component: () => import('@/views/back/admin/academy-call/awarded/Index.vue'),
              },
              {
                path: ':id',
                name: 'admin.calls.academy_awarded.show',
                component: () => import('@/views/back/university/awarded/Show.vue'),
              },
            ],
          },
          { // Extension Requests
            path: 'extension-requests',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.calls.extension-forms.index',
                component: () => import('@/views/back/admin/academy-call/extension-form/Index.vue'),
              },
            ],
          },
          { // System config
            path: 'system-config',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.system-config.index',
                component: () => import('@/views/back/admin/system-config/Index.vue'),
              },
            ],
          },
          { // Promotion calls
            path: 'promotion-calls',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.calls.promotion.index',
                component: () => import('@/views/back/admin/promotion-call/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.calls.promotion.create',
                component: () => import('@/views/back/admin/promotion-call/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.calls.promotion.edit',
                component: () => import('@/views/back/admin/promotion-call/Edit.vue'),
              },
              {
                path: 'view/:id',
                name: 'admin.calls.promotion.view',
                component: () => import('@/views/back/admin/promotion-call/View.vue'),
              },
              {
                path: 'peers-report/:id?',
                name: 'admin.calls.promotion.peers-report',
                component: () => import('@/views/back/admin/promotion-call/PeerReport.vue'),
              },
              {
                path: 'pc-members',
                name: 'admin.calls.promotion.pc-member-list',
                component: () => import('@/views/back/admin/promotion-call/pc-members/List.vue'),
              },
              {
                path: 'groups/:id',
                name: 'admin.calls.promotion.groups',
                component: () => import('@/views/back/admin/promotion-call/Groups.vue'),
              },
            ],
          },
          /* { // University
            path: 'university',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.university.index',
                component: () => import('@/views/back/university/Dashboard.vue'),
              },
            ],
          }, */
          { // Applicant calls
            path: 'applicant-calls',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.calls.applicant.index',
                component: () => import('@/views/back/admin/applicant-call/Index.vue'),
              },
            ],
          },
          { // Evaluators calls
            path: 'evaluators-calls',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.calls.evaluators.index',
                component: () => import('@/views/back/admin/evaluators-call/Index.vue'),
              },
              {
                path: 'view/:id/:action',
                name: 'admin.calls.evaluators.view',
                component: () => import('@/views/back/admin/evaluators-call/View.vue'),
              },
            ],
          },
          { // Auto notifications
            path: 'auto-notifications',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.auto-notifications.index',
                component: () => import('@/views/back/admin/auto-notifications/Index.vue'),
              },
            ],
          },
          { // Area & groups
            path: 'areas-groups',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.areas-groups.index',
                component: () => import('@/views/back/admin/areas-and-groups/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.areas-groups.create',
                component: () => import('@/views/back/admin/areas-and-groups/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.areas-groups.edit',
                component: () => import('@/views/back/admin/areas-and-groups/Edit.vue'),
              },
            ],
          },
          { // Access calls
            path: 'access-calls',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.access-calls.index',
                component: () => import('@/views/back/admin/convo_access/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.access-calls.create',
                component: () => import('@/views/back/admin/convo_access/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.access-calls.edit',
                component: () => import('@/views/back/admin/convo_access/Edit.vue'),
              },
            ],
          },
          { // Institutions
            path: 'institutions',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.institutions.index',
                component: () => import('@/views/back/admin/institutions/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.institutions.create',
                component: () => import('@/views/back/admin/institutions/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.institutions.edit',
                component: () => import('@/views/back/admin/institutions/Edit.vue'),
              },
              { // Host types
                path: 'types',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.institution-types.index',
                    component: () => import('@/views/back/admin/institutions/types/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.institution-types.create',
                    component: () => import('@/views/back/admin/institutions/types/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.institution-types.edit',
                    component: () => import('@/views/back/admin/institutions/types/Edit.vue'),
                  },
                ],
              },
              { // Host positions
                path: 'positions',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.institution-positions.index',
                    component: () => import('@/views/back/admin/institutions/positions/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.institution-positions.create',
                    component: () => import('@/views/back/admin/institutions/positions/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.institution-positions.edit',
                    component: () => import('@/views/back/admin/institutions/positions/Edit.vue'),
                  },
                ],
              },
              { // Host contacts
                path: 'contacts',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.institution-contacts.index',
                    component: () => import('@/views/back/admin/institutions/contacts/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.institution-contacts.create',
                    component: () => import('@/views/back/admin/institutions/contacts/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.institution-contacts.edit',
                    component: () => import('@/views/back/admin/institutions/contacts/Edit.vue'),
                  },
                ],
              },
              { // Host contacts
                path: 'departments',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.institution-departments.index',
                    component: () => import('@/views/back/admin/institutions/departments/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.institution-departments.create',
                    component: () => import('@/views/back/admin/institutions/departments/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.institution-departments.edit',
                    component: () => import('@/views/back/admin/institutions/departments/Edit.vue'),
                  },
                ],
              },
            ],
          },
          { // Memoir
            path: 'memoir',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: 'memoir-list',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.memoir.index',
                    component: () => import('@/views/back/admin/memoirs/List.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.memoir.create',
                    component: () => import('@/views/back/admin/memoirs/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.memoir.edit',
                    component: () => import('@/views/back/admin/memoirs/Edit.vue'),
                  },
                ],
              },
              {
                path: 'memoir-profile-view',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: 'view/:id',
                    name: 'admin.memoir-profile-view.view',
                    component: () => import('@/views/back/admin/memoirs/profiles/View.vue'),
                  },
                ],
              },
              // { // Traces
              //   path: 'traces',
              //   component: () => import('@/layouts/admin/RouterView.vue'),
              //   children: [
              //     {
              //       path: '/',
              //       name: 'admin.traces.index',
              //       component: () => import('@/views/back/admin/traces/Index.vue'),
              //     },
              //     {
              //       path: 'edit/:id',
              //       name: 'admin.traces.edit',
              //       component: () => import('@/views/back/admin/traces/Edit.vue'),
              //     },
              //   ],
              // },
            ],
          },
          { // Postdoctoral positions list
            path: 'postdoctoral-positions-list',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.positions.index',
                component: () => import('@/views/back/admin/positions/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.positions.create',
                component: () => import('@/views/back/admin/positions/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.positions.edit',
                component: () => import('@/views/back/admin/positions/Edit.vue'),
              },
            ],
          },
          { // Industrail property types
            path: 'industrial-property-types',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.industrial-property-types.index',
                component: () => import('@/views/back/admin/industrial-property-types/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.industrial-property-types.create',
                component: () => import('@/views/back/admin/industrial-property-types/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.industrial-property-types.edit',
                component: () => import('@/views/back/admin/industrial-property-types/Edit.vue'),
              },
            ],
          },
          { // Industrail property types
            path: 'industrial-property-parent-types',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.industrial-property-parent-types.index',
                component: () => import('@/views/back/admin/industrial-property-parent-types/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.industrial-property-parent-types.create',
                component: () => import('@/views/back/admin/industrial-property-parent-types/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.industrial-property-parent-types.edit',
                component: () => import('@/views/back/admin/industrial-property-parent-types/Edit.vue'),
              },
            ],
          },
          { // Education levels
            path: 'education-levels',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.education-levels.index',
                component: () => import('@/views/back/admin/education-levels/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.education-levels.create',
                component: () => import('@/views/back/admin/education-levels/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.education-levels.edit',
                component: () => import('@/views/back/admin/education-levels/Edit.vue'),
              },
            ],
          },
          { // Phrases
            path: 'phrases',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.phrases.index',
                component: () => import('@/views/back/admin/phrases/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.phrases.create',
                component: () => import('@/views/back/admin/phrases/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.phrases.edit',
                component: () => import('@/views/back/admin/phrases/Edit.vue'),
              },
            ],
          },
          { // Mail templates
            path: 'mail-templates',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.mail-templates.index',
                component: () => import('@/views/back/admin/mail-templates/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.mail-templates.create',
                component: () => import('@/views/back/admin/mail-templates/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.mail-templates.edit',
                component: () => import('@/views/back/admin/mail-templates/Edit.vue'),
              },
            ],
          },
          { // Social networks
            path: 'social-networks',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.social-network.index',
                component: () => import('@/views/back/admin/social-network/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.social-network.create',
                component: () => import('@/views/back/admin/social-network/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.social-network.edit',
                component: () => import('@/views/back/admin/social-network/Edit.vue'),
              },
            ],
          },
          /* { // Patent Status
            path: 'patent-status',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.patent-status.index',
                component: () => import('@/views/back/admin/patent-status/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.patent-status.create',
                component: () => import('@/views/back/admin/patent-status/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.patent-status.edit',
                component: () => import('@/views/back/admin/patent-status/Edit.vue'),
              },
            ],
          }, */
          { // Users
            path: 'users',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: 'new',
                name: 'admin.users.create',
                component: () => import('@/views/back/admin/users/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.users.edit',
                component: () => import('@/views/back/admin/users/Edit.vue'),
              },
              {
                path: ':role?',
                name: 'admin.users.index',
                component: () => import('@/views/back/admin/users/Index.vue'),
              },
              {
                path: ':onPromotion?',
                name: 'admin.users.index',
                component: () => import('@/views/back/admin/users/Index.vue'),
              },
            ],
          },
          { // Users
            path: 'roles',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.roles.index',
                component: () => import('@/views/back/admin/roles/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.roles.create',
                component: () => import('@/views/back/admin/roles/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.roles.edit',
                component: () => import('@/views/back/admin/roles/Edit.vue'),
              },
            ],
          },
          { // Occupational Safety & Health
            path: 'ocupational-safety',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.ocupational-safety.index',
                component: () => import('@/views/back/admin/ocupational-safety/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.ocupational-safety.create',
                component: () => import('@/views/back/admin/ocupational-safety/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.ocupational-safety.edit',
                component: () => import('@/views/back/admin/ocupational-safety/Edit.vue'),
              },
            ],
          },
          { // Occupational hazard
            path: 'ocupational-hazards',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.ocupational-hazard.index',
                component: () => import('@/views/back/admin/ocupational-hazard/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.ocupational-hazard.create',
                component: () => import('@/views/back/admin/ocupational-hazard/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.ocupational-hazard.edit',
                component: () => import('@/views/back/admin/ocupational-hazard/Edit.vue'),
              },
            ],
          },
          { // Workplaces
            path: 'workplaces',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.workplaces.index',
                component: () => import('@/views/back/admin/workplaces/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.workplaces.create',
                component: () => import('@/views/back/admin/workplaces/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.workplaces.edit',
                component: () => import('@/views/back/admin/workplaces/Edit.vue'),
              },
            ],
          },
          { // Labour hazards
            path: 'labour-hazards',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.labour-hazards.index',
                component: () => import('@/views/back/admin/labour-hazards/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.labour-hazards.create',
                component: () => import('@/views/back/admin/labour-hazards/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.labour-hazards.edit',
                component: () => import('@/views/back/admin/labour-hazards/Edit.vue'),
              },
              {
                path: 'shipments',
                name: 'admin.labour-hazards.shipments',
                component: () => import('@/views/back/admin/labour-hazards/Shipments.vue'),
              },
            ],
          },
          { // Insurances
            path: 'insurances',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.insurances.index',
                component: () => import('@/views/back/admin/insurances/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.insurances.create',
                component: () => import('@/views/back/admin/insurances/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.insurances.edit',
                component: () => import('@/views/back/admin/insurances/Edit.vue'),
              },
            ],
          },
          { // TSS Management
            path: 'tss-management',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.tss-management.index',
                component: () => import('@/views/back/admin/tss-management/Index.vue'),
              },
            ],
          },
          { // Change requests
            path: 'change-requests',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.change-requests.index',
                component: () => import('@/views/back/admin/change-requests/Index.vue'),
              },
            ],
          },
          { // Status management
            path: 'status-management',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.status-management.index',
                component: () => import('@/views/back/admin/status-management/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.status-management.create',
                component: () => import('@/views/back/admin/status-management/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.status-management.edit',
                component: () => import('@/views/back/admin/status-management/Edit.vue'),
              },
            ],
          },
          { // Activities
            path: 'activities',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: 'types',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.activity-types.index',
                    component: () => import('@/views/back/admin/activities/types/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.activity-types.create',
                    component: () => import('@/views/back/admin/activities/types/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.activity-types.edit',
                    component: () => import('@/views/back/admin/activities/types/Edit.vue'),
                  },
                ],
              },
              {
                path: 'status-authorization',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.status-authorization.index',
                    component: () => import('@/views/back/admin/activities/status-authorization/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.status-authorization.create',
                    component: () => import('@/views/back/admin/activities/status-authorization/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.status-authorization.edit',
                    component: () => import('@/views/back/admin/activities/status-authorization/Edit.vue'),
                  },
                ],
              },
              {
                path: 'types-phd',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.types-phd.index',
                    component: () => import('@/views/back/admin/activities/types-phd/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.types-phd.create',
                    component: () => import('@/views/back/admin/activities/types-phd/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.types-phd.edit',
                    component: () => import('@/views/back/admin/activities/types-phd/Edit.vue'),
                  },
                ],
              },
              {
                path: 'roles-phd',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.roles-phd.index',
                    component: () => import('@/views/back/admin/activities/roles-phd/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.roles-phd.create',
                    component: () => import('@/views/back/admin/activities/roles-phd/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.roles-phd.edit',
                    component: () => import('@/views/back/admin/activities/roles-phd/Edit.vue'),
                  },
                ],
              },
              {
                path: 'course-types',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.course-types.index',
                    component: () => import('@/views/back/admin/activities/course-types/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.course-types.create',
                    component: () => import('@/views/back/admin/activities/course-types/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.course-types.edit',
                    component: () => import('@/views/back/admin/activities/course-types/Edit.vue'),
                  },
                ],
              },
              {
                path: 'managerial-types',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.managerial-types.index',
                    component: () => import('@/views/back/admin/activities/managerial-types/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.managerial-types.create',
                    component: () => import('@/views/back/admin/activities/managerial-types/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.managerial-types.edit',
                    component: () => import('@/views/back/admin/activities/managerial-types/Edit.vue'),
                  },
                ],
              },
              {
                path: 'dissemination-types',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.dissemination-types.index',
                    component: () => import('@/views/back/admin/activities/dissemination-types/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.dissemination-types.create',
                    component: () => import('@/views/back/admin/activities/dissemination-types/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.dissemination-types.edit',
                    component: () => import('@/views/back/admin/activities/dissemination-types/Edit.vue'),
                  },
                ],
              },
              {
                path: 'stay-research-types',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.stay-research-types.index',
                    component: () => import('@/views/back/admin/activities/stay-research-types/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.stay-research-types.create',
                    component: () => import('@/views/back/admin/activities/stay-research-types/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.stay-research-types.edit',
                    component: () => import('@/views/back/admin/activities/stay-research-types/Edit.vue'),
                  },
                ],
              },
            ],
          },
          { // Publications
            path: 'publications',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: 'types',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.publication-types.index',
                    component: () => import('@/views/back/admin/publications/types/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.publication-types.create',
                    component: () => import('@/views/back/admin/publications/types/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.publication-types.edit',
                    component: () => import('@/views/back/admin/publications/types/Edit.vue'),
                  },
                ],
              },
              {
                path: 'status',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.publication-status.index',
                    component: () => import('@/views/back/admin/publications/status/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.publication-status.create',
                    component: () => import('@/views/back/admin/publications/status/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.publication-status.edit',
                    component: () => import('@/views/back/admin/publications/status/Edit.vue'),
                  },
                ],
              },
              {
                path: 'stages',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.publication-stages.index',
                    component: () => import('@/views/back/admin/publications/stages/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.publication-stages.create',
                    component: () => import('@/views/back/admin/publications/stages/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.publication-stages.edit',
                    component: () => import('@/views/back/admin/publications/stages/Edit.vue'),
                  },
                ],
              },
            ],
          },
          { // Administration
            path: 'administration',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: 'residency-documentation-authorization',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.residency-documentation.authorization.index',
                    component: () => import('@/views/back/admin/administration/residency-documentation/authorization/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.residency-documentation.authorization.create',
                    component: () => import('@/views/back/admin/administration/residency-documentation/authorization/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.residency-documentation.authorization.edit',
                    component: () => import('@/views/back/admin/administration/residency-documentation/authorization/Edit.vue'),
                  },
                ],
              },
              {
                path: 'residency-documentation-stages',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.residency-documentation.stage.index',
                    component: () => import('@/views/back/admin/administration/residency-documentation/stages/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.residency-documentation.stage.create',
                    component: () => import('@/views/back/admin/administration/residency-documentation/stages/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.residency-documentation.stage.edit',
                    component: () => import('@/views/back/admin/administration/residency-documentation/stages/Edit.vue'),
                  },
                ],
              },
              { // Traces
                path: 'traces',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.traces.index',
                    component: () => import('@/views/back/admin/traces/Index.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.traces.edit',
                    component: () => import('@/views/back/admin/traces/Edit.vue'),
                  },
                ],
              },
            ],
          },
          { // Scientific production
            path: 'scientific-production',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: 'scientific-production-stats',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.scientific-production.scientific-production-stats.index',
                    component: () => import('@/views/back/admin/scientific-production-stats/ScientificProductionStats.vue'),
                  },
                ],
              },
            ],
          },
          { // Type of contracts
            path: 'contract-types',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.contract-types.index',
                component: () => import('@/views/back/admin/type-contracts/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.contract-types.create',
                component: () => import('@/views/back/admin/type-contracts/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.contract-types.edit',
                component: () => import('@/views/back/admin/type-contracts/Edit.vue'),
              },
            ],
          },
          { // Payroll versions
            path: 'payroll-versions',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.payroll-versions.index',
                component: () => import('@/views/back/admin/payroll-versions/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.payroll-versions.create',
                component: () => import('@/views/back/admin/payroll-versions/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.payroll-versions.edit',
                component: () => import('@/views/back/admin/payroll-versions/Edit.vue'),
              },
            ],
          },
          { // Request forms
            path: 'request-forms',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.request-forms.index',
                component: () => import('@/views/back/admin/request-forms/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.request-forms.create',
                component: () => import('@/views/back/admin/request-forms/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.request-forms.edit',
                component: () => import('@/views/back/admin/request-forms/Edit.vue'),
              },
              {
                path: 'view/:id',
                name: 'admin.request-forms.view',
                component: () => import('@/views/back/admin/request-forms/View.vue'),
              },
            ],
          },
          { // Grants
            path: 'grants',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: 'types-of-resource',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.types-of-resource.index',
                    component: () => import('@/views/back/admin/grants/types-of-resource/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.types-of-resource.create',
                    component: () => import('@/views/back/admin/grants/types-of-resource/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.types-of-resource.edit',
                    component: () => import('@/views/back/admin/grants/types-of-resource/Edit.vue'),
                  },
                ],
              },
              {
                path: 'types-of-funding',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.types-of-funding.index',
                    component: () => import('@/views/back/admin/grants/types-of-funding/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.types-of-funding.create',
                    component: () => import('@/views/back/admin/grants/types-of-funding/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.types-of-funding.edit',
                    component: () => import('@/views/back/admin/grants/types-of-funding/Edit.vue'),
                  },
                ],
              },
              {
                path: 'funding-agencies',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.funding-agencies.index',
                    component: () => import('@/views/back/admin/grants/funding-agencies/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.funding-agencies.create',
                    component: () => import('@/views/back/admin/grants/funding-agencies/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.funding-agencies.edit',
                    component: () => import('@/views/back/admin/grants/funding-agencies/Edit.vue'),
                  },
                ],
              },
              {
                path: 'programmes',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.programmes.index',
                    component: () => import('@/views/back/admin/grants/programmes/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.programmes.create',
                    component: () => import('@/views/back/admin/grants/programmes/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.programmes.edit',
                    component: () => import('@/views/back/admin/grants/programmes/Edit.vue'),
                  },
                ],
              },
              {
                path: 'positions',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.grants-positions.index',
                    component: () => import('@/views/back/admin/grants/positions/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.grants-positions.create',
                    component: () => import('@/views/back/admin/grants/positions/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.grants-positions.edit',
                    component: () => import('@/views/back/admin/grants/positions/Edit.vue'),
                  },
                ],
              },
              {
                path: 'types-of-personnel',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'admin.types-of-personnel.index',
                    component: () => import('@/views/back/admin/grants/types-of-personnel/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'admin.types-of-personnel.create',
                    component: () => import('@/views/back/admin/grants/types-of-personnel/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'admin.types-of-personnel.edit',
                    component: () => import('@/views/back/admin/grants/types-of-personnel/Edit.vue'),
                  },
                ],
              },
            ],
          },
          { // Insurance types
            path: 'insurance-types',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.insurance-types.index',
                component: () => import('@/views/back/admin/insurance-types/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.insurance-types.create',
                component: () => import('@/views/back/admin/insurance-types/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.insurance-types.edit',
                component: () => import('@/views/back/admin/insurance-types/Edit.vue'),
              },
            ],
          },
          { // Card types
            path: 'card-types',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.card-types.index',
                component: () => import('@/views/back/admin/card-types/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.card-types.create',
                component: () => import('@/views/back/admin/card-types/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.card-types.edit',
                component: () => import('@/views/back/admin/card-types/Edit.vue'),
              },
            ],
          },
          { // Intranet logs
            path: 'intranet-logs',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.intranet-logs.index',
                component: () => import('@/views/back/admin/intranet-logs/Index.vue'),
              },
            ],
          },
          { // Trace typologies
            path: 'trace-typologies',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.trace-typologies.index',
                component: () => import('@/views/back/admin/trace-typologies/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.trace-typologies.create',
                component: () => import('@/views/back/admin/trace-typologies/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.trace-typologies.edit',
                component: () => import('@/views/back/admin/trace-typologies/Edit.vue'),
              },
            ],
          },
          { // Type of leaves
            path: 'type-of-leaves',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.type-of-leaves.index',
                component: () => import('@/views/back/admin/type-of-leaves/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.type-of-leaves.create',
                component: () => import('@/views/back/admin/type-of-leaves/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.type-of-leaves.edit',
                component: () => import('@/views/back/admin/type-of-leaves/Edit.vue'),
              },
            ],
          },
          { // ERC
            path: 'ercs',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.erc.index',
                component: () => import('@/views/back/admin/erc/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.erc.create',
                component: () => import('@/views/back/admin/erc/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.erc.edit',
                component: () => import('@/views/back/admin/erc/Edit.vue'),
              },
              {
                path: 'reorganize',
                name: 'admin.erc.reorganize',
                component: () => import('@/views/back/admin/erc/Reorganize.vue'),
              },
              {
                path: 'affected-users',
                name: 'admin.erc.affected-users',
                component: () => import('@/views/back/admin/erc/AffectedUsers.vue'),
              },
            ],
          },
          { // Modal texts
            path: 'modal-texts',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.modal-texts.index',
                component: () => import('@/views/back/admin/modal-texts/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.modal-texts.create',
                component: () => import('@/views/back/admin/modal-texts/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.modal-texts.edit',
                component: () => import('@/views/back/admin/modal-texts/Edit.vue'),
              },
            ],
          },
          { // Genres
            path: 'genres',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.genres.index',
                component: () => import('@/views/back/admin/genres/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.genres.create',
                component: () => import('@/views/back/admin/genres/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.genres.edit',
                component: () => import('@/views/back/admin/genres/Edit.vue'),
              },
            ],
          },
          { // User permission payrolls
            path: 'user-permission-payrolls',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.user-permission-payrolls.index',
                component: () => import('@/views/back/admin/user-permission-payrolls/Index.vue'),
              },
              // {
              //   path: 'new',
              //   name: 'admin.user-permission-payrolls.create',
              //   component: () => import('@/views/back/admin/user-permission-payrolls/Edit.vue'),
              // },
              // {
              //   path: 'edit/:id',
              //   name: 'admin.user-permission-payrolls.edit',
              //   component: () => import('@/views/back/admin/user-permission-payrolls/Edit.vue'),
              // },
            ],
          },
          { // Supplement Types
            path: 'supplement-types',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.supplement-types.index',
                component: () => import('@/views/back/admin/supplement-types/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.supplement-types.create',
                component: () => import('@/views/back/admin/supplement-types/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.supplement-types.edit',
                component: () => import('@/views/back/admin/supplement-types/Edit.vue'),
              },
            ],
          },
          { // Temporary leave Types
            path: 'temporary-leave-types',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.temporary-leave-types.index',
                component: () => import('@/views/back/admin/temporary-leave-types/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.temporary-leave-types.create',
                component: () => import('@/views/back/admin/temporary-leave-types/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.temporary-leave-types.edit',
                component: () => import('@/views/back/admin/temporary-leave-types/Edit.vue'),
              },
            ],
          },
          { // Dedication types
            path: 'dedication-types',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.dedication-types.index',
                component: () => import('@/views/back/admin/dedication-types/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.dedication-types.create',
                component: () => import('@/views/back/admin/dedication-types/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.dedication-types.edit',
                component: () => import('@/views/back/admin/dedication-types/Edit.vue'),
              },
            ],
          },
          { // ICREA status
            path: 'icrea-status',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.icrea-status.index',
                component: () => import('@/views/back/admin/icrea-status/Index.vue'),
              },
              {
                path: 'new',
                name: 'admin.icrea-status.create',
                component: () => import('@/views/back/admin/icrea-status/Edit.vue'),
              },
              {
                path: 'edit/:id',
                name: 'admin.icrea-status.edit',
                component: () => import('@/views/back/admin/icrea-status/Edit.vue'),
              },
            ],
          },
          { // Section labels
            path: 'texts-managements',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.section-labels.index',
                component: () => import('@/views/back/admin/section-labels/Index.vue'),
              },
              {
                path: 'edit/:section',
                name: 'admin.section-labels.edit',
                component: () => import('@/views/back/admin/section-labels/Edit.vue'),
              },
              {
                path: 'search',
                name: 'admin.section-labels.search',
                component: () => import('@/views/back/admin/section-labels/Search.vue'),
              },
            ],
          },
          { // Mail logger
            path: 'mail-logger',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.mail-logger.index',
                component: () => import('@/views/back/admin/mail-logger/Index.vue'),
              },
            ],
          },
          { // Report
            path: 'reports',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'admin.reports.index',
                component: () => import('@/views/back/admin/reports/Index.vue'),
              },
              {
                path: ':report_id',
                name: 'admin.reports.show',
                component: () => import('@/views/back/admin/reports/Show.vue'),
              },
            ],
          },
        ],
      },
      { // Researcher zone
        path: 'researcher',
        component: () => import('@/layouts/admin/RouterView.vue'),
        children: [
          { // Promotion call
            path: 'promotion-call',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: 'add-peer',
                name: 'researcher.promotion-call.add-peer',
                component: () => import('@/views/back/researcher/promotion-call/AddPeer.vue'),
              },
              {
                path: ':researcher_id?',
                name: 'researcher.promotion-call.index',
                component: () => import('@/views/back/researcher/promotion-call/Index.vue'),
              },
            ],
          },
          { // Personal Data
            path: 'personal-data',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'researcher.personal-data.index',
                component: () => import('@/views/back/researcher/personal-data/Edit.vue'),
                meta: { requiresPermission: 'view personal data' },
              },
            ],
          },
          { // Research Lines
            path: 'research-lines',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'researcher.research-lines.index',
                component: () => import('@/views/back/researcher/research-lines/Index.vue'),
                meta: { requiresPermission: 'view research lines' },
              },
              {
                path: 'new',
                name: 'researcher.research-lines.create',
                component: () => import('@/views/back/researcher/research-lines/Edit.vue'),
                meta: { requiresPermission: 'create research lines' },
              },
              {
                path: 'edit/:id',
                name: 'researcher.research-lines.edit',
                component: () => import('@/views/back/researcher/research-lines/Edit.vue'),
                meta: { requiresPermission: 'update research lines' },
              },
            ],
          },
          { // Professional Background
            path: 'professional-background',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'researcher.professional-background.index',
                component: () => import('@/views/back/researcher/professional-background/Index.vue'),
                meta: { requiresPermission: 'view professional backgrounds' },
              },
              {
                path: 'new',
                name: 'researcher.professional-background.create',
                component: () => import('@/views/back/researcher/professional-background/Edit.vue'),
                meta: { requiresPermission: 'create professional backgrounds' },
              },
              {
                path: 'edit/:id',
                name: 'researcher.professional-background.edit',
                component: () => import('@/views/back/researcher/professional-background/Edit.vue'),
                meta: { requiresPermission: 'update professional backgrounds' },
              },
            ],
          },
          { // Postdoctoral positions & fellowships
            path: 'postdoctoral-positions',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'researcher.postdoctoral-positions.index',
                component: () => import('@/views/back/researcher/postdoctoral-positions/Index.vue'),
                meta: { requiresPermission: 'view postdoctoral positions' },
              },
              {
                path: 'new',
                name: 'researcher.postdoctoral-positions.create',
                component: () => import('@/views/back/researcher/postdoctoral-positions/Edit.vue'),
                meta: { requiresPermission: 'create postdoctoral positions' },
              },
              {
                path: 'edit/:id',
                name: 'researcher.postdoctoral-positions.edit',
                component: () => import('@/views/back/researcher/postdoctoral-positions/Edit.vue'),
                meta: { requiresPermission: 'update postdoctoral positions' },
              },
            ],
          },
          { // Educations
            path: 'educations',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'researcher.education.index',
                component: () => import('@/views/back/researcher/education/Index.vue'),
                meta: { requiresPermission: 'view educations' },
              },
              {
                path: 'new',
                name: 'researcher.education.create',
                component: () => import('@/views/back/researcher/education/Edit.vue'),
                meta: { requiresPermission: 'create educations' },
              },
              {
                path: 'edit/:id',
                name: 'researcher.education.edit',
                component: () => import('@/views/back/researcher/education/Edit.vue'),
                meta: { requiresPermission: 'update educations' },
              },
            ],
          },
          { // Honours & Awards
            path: 'honours-awards',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'researcher.honours-awards.index',
                component: () => import('@/views/back/researcher/honours-awards/Index.vue'),
                meta: { requiresPermission: 'view honours and awards' },
              },
              {
                path: 'new',
                name: 'researcher.honours-awards.create',
                component: () => import('@/views/back/researcher/honours-awards/Edit.vue'),
                meta: { requiresPermission: 'create honours and awards' },
              },
              {
                path: 'edit/:id',
                name: 'researcher.honours-awards.edit',
                component: () => import('@/views/back/researcher/honours-awards/Edit.vue'),
                meta: { requiresPermission: 'update honours and awards' },
              },
            ],
          },
          { // Scientific Production Report
            path: 'scientific-production-report',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'researcher.scientific-production-report.index',
                component: () => import('@/views/back/researcher/scientific-production-report/ScientificProductionReport.vue'),
                meta: { requiresPermission: 'view scientific production report' },
              },
            ],
          },
          { // Tecnology transfer
            path: 'tecnology-transfer',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: 'industrial-property',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.industrial-property.index',
                    component: () => import('@/views/back/researcher/industrial-property/Index.vue'),
                    meta: { requiresPermission: 'view industrial properties' },
                  },
                  {
                    path: 'new',
                    name: 'researcher.industrial-property.create',
                    component: () => import('@/views/back/researcher/industrial-property/Edit.vue'),
                    meta: { requiresPermission: 'create industrial properties' },
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.industrial-property.edit',
                    component: () => import('@/views/back/researcher/industrial-property/Edit.vue'),
                    meta: { requiresPermission: 'update industrial properties' },
                  },
                ],
              },
              {
                path: 'spin-offs',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.spin-offs.index',
                    component: () => import('@/views/back/researcher/spin-offs/Index.vue'),
                    meta: { requiresPermission: 'view spin-offs' },
                  },
                  {
                    path: 'new',
                    name: 'researcher.spin-offs.create',
                    component: () => import('@/views/back/researcher/spin-offs/Edit.vue'),
                    meta: { requiresPermission: 'create spin-offs' },
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.spin-offs.edit',
                    component: () => import('@/views/back/researcher/spin-offs/Edit.vue'),
                    meta: { requiresPermission: 'update spin-offs' },
                  },
                ],
              },
            ],
          },
          { // Researcher memoir
            path: 'memoir',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: 'memoir-list',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.memoir.index',
                    component: () => import('@/views/back/admin/memoirs/researcher/List.vue'),
                  },
                ],
              },
              {
                path: 'memoir-view',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: 'view/:id',
                    name: 'researcher.memoir-view.view',
                    component: () => import('@/views/back/admin/memoirs/profiles/View.vue'),
                  },
                ],
              },
              {
                path: 'memoir-profile',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.memoir-profile.index',
                    component: () => import('@/views/back/admin/memoirs/profiles/Index.vue'),
                    meta: { requiresPermission: 'view Memoir profiles' },
                  },
                  {
                    path: 'new',
                    name: 'researcher.memoir-profile.create',
                    component: () => import('@/views/back/admin/memoirs/profiles/Edit.vue'),
                    meta: { requiresPermission: 'create Memoir profiles' },
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.memoir-profile.edit',
                    component: () => import('@/views/back/admin/memoirs/profiles/Edit.vue'),
                    meta: { requiresPermission: 'update Memoir profiles' },
                  },
                ],
              },
              {
                path: 'memoir-highlight',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.memoir-highlight.index',
                    component: () => import('@/views/back/admin/memoirs/highlights/Index.vue'),
                    meta: { requiresPermission: 'view Memoir highlights' },
                  },
                  {
                    path: 'new',
                    name: 'researcher.memoir-highlight.create',
                    component: () => import('@/views/back/admin/memoirs/highlights/Edit.vue'),
                    meta: { requiresPermission: 'create Memoir highlights' },
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.memoir-highlight.edit',
                    component: () => import('@/views/back/admin/memoirs/highlights/Edit.vue'),
                    meta: { requiresPermission: 'update Memoir highlights' },
                  },
                  {
                    path: 'view/:id',
                    name: 'researcher.memoir-highlight.view',
                    component: () => import('@/views/back/admin/memoirs/highlights/View.vue'),
                    meta: { requiresPermission: 'view Memoir highlights' },
                  },
                ],
              },
            ],
          },
          { // Other activities
            path: 'activities',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: 'conferences',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.conferences.index',
                    component: () => import('@/views/back/researcher/activities/conferences/Index.vue'),
                    meta: { requiresPermission: 'view conferences' },
                  },
                  {
                    path: 'new',
                    name: 'researcher.conferences.create',
                    component: () => import('@/views/back/researcher/activities/Edit.vue'),
                    meta: { requiresPermission: 'create conferences' },
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.conferences.edit',
                    component: () => import('@/views/back/researcher/activities/Edit.vue'),
                    meta: { requiresPermission: 'update conferences' },
                  },
                ],
              },
              {
                path: 'phds',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.phds.index',
                    component: () => import('@/views/back/researcher/activities/phds/Index.vue'),
                    meta: { requiresPermission: 'view phds' },
                  },
                  {
                    path: 'new',
                    name: 'researcher.phds.create',
                    component: () => import('@/views/back/researcher/activities/Edit.vue'),
                    meta: { requiresPermission: 'create phds' },
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.phds.edit',
                    component: () => import('@/views/back/researcher/activities/Edit.vue'),
                    meta: { requiresPermission: 'update phds' },
                  },
                ],
              },
              {
                path: 'courses',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.courses.index',
                    component: () => import('@/views/back/researcher/activities/courses/Index.vue'),
                    meta: { requiresPermission: 'view courses' },
                  },
                  {
                    path: 'new',
                    name: 'researcher.courses.create',
                    component: () => import('@/views/back/researcher/activities/Edit.vue'),
                    meta: { requiresPermission: 'create courses' },
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.courses.edit',
                    component: () => import('@/views/back/researcher/activities/Edit.vue'),
                    meta: { requiresPermission: 'update courses' },
                  },
                ],
              },
              {
                path: 'managerial',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.managerial.index',
                    component: () => import('@/views/back/researcher/activities/managerial/Index.vue'),
                    meta: { requiresPermission: 'view managerial activities' },
                  },
                  {
                    path: 'new',
                    name: 'researcher.managerial.create',
                    component: () => import('@/views/back/researcher/activities/Edit.vue'),
                    meta: { requiresPermission: 'create managerial activities' },
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.managerial.edit',
                    component: () => import('@/views/back/researcher/activities/Edit.vue'),
                    meta: { requiresPermission: 'update managerial activities' },
                  },
                ],
              },
              {
                path: 'stays-research',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.stays-research.index',
                    component: () => import('@/views/back/researcher/activities/stays-research/Index.vue'),
                    meta: { requiresPermission: 'view stays of research' },
                  },
                  {
                    path: 'new',
                    name: 'researcher.stays-research.create',
                    component: () => import('@/views/back/researcher/activities/Edit.vue'),
                    meta: { requiresPermission: 'create stays of research' },
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.stays-research.edit',
                    component: () => import('@/views/back/researcher/activities/Edit.vue'),
                    meta: { requiresPermission: 'update stays of research' },
                  },
                ],
              },
              {
                path: 'disseminations',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.disseminations.index',
                    component: () => import('@/views/back/researcher/activities/disseminations/Index.vue'),
                    meta: { requiresPermission: 'view disseminations' },
                  },
                  {
                    path: 'new',
                    name: 'researcher.disseminations.create',
                    component: () => import('@/views/back/researcher/activities/Edit.vue'),
                    meta: { requiresPermission: 'create disseminations' },
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.disseminations.edit',
                    component: () => import('@/views/back/researcher/activities/Edit.vue'),
                    meta: { requiresPermission: 'update disseminations' },
                  },
                ],
              },
            ],
          },
          { // Insurances
            path: 'insurances',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'researcher.insurances.index',
                component: () => import('@/views/back/admin/insurances/Index.vue'),
                meta: { requiresPermission: 'view insurances' },
              },
            ],
          },
          { // Publications
            path: 'publications',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: 'list',
                name: 'researcher.publications.index',
                component: () => import('@/views/back/researcher/publications/Index.vue'),
                meta: { requiresPermission: 'view publications' },
              },
              {
                path: 'new',
                name: 'researcher.publications.create',
                component: () => import('@/views/back/researcher/publications/Edit.vue'),
                meta: { requiresPermission: 'create publications' },
              },
              {
                path: 'edit/:id',
                name: 'researcher.publications.edit',
                component: () => import('@/views/back/researcher/publications/Edit.vue'),
                meta: { requiresPermission: 'update publications' },
              },
              {
                path: 'wos-import',
                name: 'admin.publications.import',
                component: () => import('@/views/back/researcher/publications/Import.vue'),
              },
              {
                path: 'wos-import/edit/:id',
                name: 'admin.publications.import.edit',
                component: () => import('@/views/back/researcher/publications/ImportEdit.vue'),
              },
            ],
          },
          { // Administration
            path: 'administration',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              { // Residency documentation
                path: 'residency-documentation',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.administration.residency-documentation.index',
                    component: () => import('@/views/back/researcher/administration/residency-documentation/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'researcher.administration.residency-documentation.create',
                    component: () => import('@/views/back/researcher/administration/residency-documentation/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.administration.residency-documentation.edit',
                    component: () => import('@/views/back/researcher/administration/residency-documentation/Edit.vue'),
                  },
                ],
              },
              { // Sabbaticals
                path: 'sabbaticals',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.administration.sabbaticals.index',
                    component: () => import('@/views/back/researcher/administration/sabbaticals/Index.vue'),
                    meta: { requiresPermission: 'view sabbaticals' },
                  },
                  {
                    path: 'new',
                    name: 'researcher.administration.sabbaticals.create',
                    component: () => import('@/views/back/researcher/administration/sabbaticals/Edit.vue'),
                    meta: { requiresPermission: 'create sabbaticals' },
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.administration.sabbaticals.edit',
                    component: () => import('@/views/back/researcher/administration/sabbaticals/Edit.vue'),
                    meta: { requiresPermission: 'update sabbaticals' },
                  },
                ],
              },
              { // Unpaid leaves of absense
                path: 'unpaid-leaves-of-absence',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.administration.unpaid-leaves.index',
                    component: () => import('@/views/back/researcher/administration/unpaid-leaves-of-absence/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'researcher.administration.unpaid-leaves.create',
                    component: () => import('@/views/back/researcher/administration/unpaid-leaves-of-absence/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.administration.unpaid-leaves.edit',
                    component: () => import('@/views/back/researcher/administration/unpaid-leaves-of-absence/Edit.vue'),
                  },
                ],
              },
              { // Other sections
                path: 'other-sections',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.administration.other-sections.index',
                    component: () => import('@/views/back/researcher/administration/other-sections/Index.vue'),
                    meta: { requiresPermission: 'view other sections' },
                  },
                  {
                    path: 'new',
                    name: 'researcher.administration.other-sections.create',
                    component: () => import('@/views/back/researcher/administration/other-sections/Edit.vue'),
                    meta: { requiresPermission: 'create other sections' },
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.administration.other-sections.edit',
                    component: () => import('@/views/back/researcher/administration/other-sections/Edit.vue'),
                    meta: { requiresPermission: 'update other sections' },
                  },
                ],
              },
              { // Collaborators
                path: 'collaborators',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.administration.collaborators.index',
                    component: () => import('@/views/back/researcher/collaborators/Index.vue'),
                    meta: { requiresPermission: 'view collaborators' },
                  },
                  {
                    path: 'new',
                    name: 'researcher.administration.collaborators.create',
                    component: () => import('@/views/back/researcher/collaborators/Edit.vue'),
                    meta: { requiresPermission: 'create collaborators' },
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.administration.collaborators.edit',
                    component: () => import('@/views/back/researcher/collaborators/Edit.vue'),
                    meta: { requiresPermission: 'update collaborators' },
                  },
                ],
              },
              { // Other contracts
                path: 'other-contracts',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.administration.other-contracts.index',
                    component: () => import('@/views/back/researcher/administration/other-contracts/Index.vue'),
                    meta: { requiresPermission: 'view other contracts' },
                  },
                  {
                    path: 'new',
                    name: 'researcher.administration.other-contracts.create',
                    component: () => import('@/views/back/researcher/administration/other-contracts/Edit.vue'),
                    meta: { requiresPermission: 'create other contracts' },
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.administration.other-contracts.edit',
                    component: () => import('@/views/back/researcher/administration/other-contracts/Edit.vue'),
                    meta: { requiresPermission: 'update other contracts' },
                  },
                ],
              },
              { // Payrolls
                path: 'payrolls',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.administration.payrolls.index',
                    component: () => import('@/views/back/researcher/administration/payrolls/Index.vue'),
                    meta: { requiresPermission: 'view payrolls' },
                  },
                ],
              },
              { // Salary History
                path: 'salary-history',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.administration.salary-history.index',
                    component: () => import('@/views/back/researcher/administration/salary-histories/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'researcher.administration.salary-history.create',
                    component: () => import('@/views/back/researcher/administration/salary-histories/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.administration.salary-history.edit',
                    component: () => import('@/views/back/researcher/administration/salary-histories/Edit.vue'),
                  },
                ],
              },
              { // Supplements
                path: 'supplements',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.administration.supplements.index',
                    component: () => import('@/views/back/researcher/administration/supplements/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'researcher.administration.supplements.create',
                    component: () => import('@/views/back/researcher/administration/supplements/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.administration.supplements.edit',
                    component: () => import('@/views/back/researcher/administration/supplements/Edit.vue'),
                  },
                ],
              },
              { // Temporary leave
                path: 'temporary-leaves',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.administration.temporary-leaves.index',
                    component: () => import('@/views/back/researcher/administration/temporary-leaves/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'researcher.administration.temporary-leaves.create',
                    component: () => import('@/views/back/researcher/administration/temporary-leaves/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.administration.temporary-leaves.edit',
                    component: () => import('@/views/back/researcher/administration/temporary-leaves/Edit.vue'),
                  },
                ],
              },
              { // Dedications
                path: 'dedications',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.administration.dedications.index',
                    component: () => import('@/views/back/researcher/administration/dedications/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'researcher.administration.dedications.create',
                    component: () => import('@/views/back/researcher/administration/dedications/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.administration.dedications.edit',
                    component: () => import('@/views/back/researcher/administration/dedications/Edit.vue'),
                  },
                ],
              },
              { // Evaluator Histories
                path: 'evaluator-history',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.administration.evaluator-history.index',
                    component: () => import('@/views/back/researcher/administration/evaluator-history/Index.vue'),
                  },
                  {
                    path: 'new',
                    name: 'researcher.administration.evaluator-history.create',
                    component: () => import('@/views/back/researcher/administration/evaluator-history/Edit.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.administration.evaluator-history.edit',
                    component: () => import('@/views/back/researcher/administration/evaluator-history/Edit.vue'),
                  },
                ],
              },
              { // Host Histories
                path: 'host-history',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.administration.host-history.index',
                    component: () => import('@/views/back/researcher/administration/host-history/Index.vue'),
                  },
                  {
                    path: 'edit/:id',
                    name: 'researcher.administration.host-history.edit',
                    component: () => import('@/views/back/researcher/administration/host-history/Edit.vue'),
                  },
                ],
              },
              { // Promotion history
                path: 'promotion-history',
                component: () => import('@/layouts/admin/RouterView.vue'),
                children: [
                  {
                    path: '/',
                    name: 'researcher.administration.promotion-history.index',
                    component: () => import('@/views/back/researcher/administration/promotion-history/Index.vue'),
                  },
                  {
                    path: 'see-evaluation',
                    name: 'researcher.administration.promotion-history.see-evaluation',
                    component: () => import('@/views/back/evaluator/promotion/FormReport.vue'),
                  },
                ],
              },
            ],
          },
          { // Grants & resources
            path: 'grants',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'researcher.grants.index',
                component: () => import('@/views/back/researcher/grants/Index.vue'),
                meta: { requiresPermission: 'view grants' },
              },
              {
                path: 'new',
                name: 'researcher.grants.create',
                component: () => import('@/views/back/researcher/grants/Edit.vue'),
                meta: { requiresPermission: 'create grants' },

              },
              {
                path: 'edit/:id',
                name: 'researcher.grants.edit',
                component: () => import('@/views/back/researcher/grants/Edit.vue'),
                meta: { requiresPermission: 'update grants' },

              },
              {
                path: 'dashboard',
                name: 'researcher.grants.dashboard',
                component: () => import('@/views/back/researcher/grants/Dashboard.vue'),
                meta: { requiresPermission: 'view grants' },
              },
            ],
          },
          { // Administrative data
            path: 'administrative-data',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'researcher.administrative-data',
                component: () => import('@/views/back/researcher/administrative-data/AdministrativeData.vue'),
                meta: { requiresPermission: 'view administrative data' },
              },
              {
                path: ':id?',
                name: 'researcher.administrative-data.researcher',
                component: () => import('@/views/back/researcher/administrative-data/AdministrativeData.vue'),
                meta: { requiresPermission: 'view administrative data' },
              },
            ],
          },
          { // Occupational safeties
            path: 'occupational-hazards',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'researcher.occupational-hazards.index',
                component: () => import('@/views/back/cae/Index.vue'),
              },
            ],
          },
          { // Administrative data
            path: 'edit-access',
            component: () => import('@/layouts/admin/RouterView.vue'),
            children: [
              {
                path: '/',
                name: 'researcher.edit-access',
                component: () => import('@/views/back/researcher/edit-intranet-access/Index.vue'),
                meta: { requiresPermission: 'view occupational safeties' },
              },
            ],
          },
          {
            path: ':id?',
            name: 'researcher.dashboard',
            component: () => import('@/views/back/researcher/dashboard/Dashboard.vue'),
          },
        ],
      },
      { // Evaluator zones
        path: 'awarded',
        component: () => import('@/layouts/admin/RouterView.vue'),
        children: [
          { // Profile
            path: 'profile',
            name: 'awarded.profile',
            component: () => import('@/views/back/admin/users/Edit.vue'),
          },
          { // List of awardees
            path: 'awardees-list',
            name: 'awarded.awardees-list',
            component: () => import('@/views/back/awardees/Index.vue'),
          },
          { // Edit info
            path: 'awardees-list/edit/:id',
            name: 'awarded.awardees-list-edit',
            component: () => import('@/views/back/awardees/Edit.vue'),
          },
        ],
      },
      { // Request forms
        path: 'forms',
        component: () => import('@/layouts/admin/RouterView.vue'),
        children: [
          { // Show current form
            path: ':form',
            name: 'request-forms.show',
            component: () => import('@/views/back/forms/Form.vue'),
          },
          { // ok form
            path: 'ok',
            name: 'request-forms.ok',
            component: () => import('@/views/back/forms/OkForm.vue'),
          },
        ],
      },
      { // CAE zone
        path: 'cae',
        component: () => import('@/layouts/admin/RouterView.vue'),
        children: [
          {
            path: '/',
            name: 'cae.index',
            component: () => import('@/views/back/cae/Index.vue'),
          },
          {
            path: 'view/:id',
            name: 'cae.view',
            component: () => import('@/views/back/cae/View.vue'),
          },
          {
            path: 'view-document/:id',
            name: 'cae.view-document',
            component: () => import('@/views/back/cae/Document.vue'),
          },
          {
            path: 'filter-files',
            name: 'cae.filter',
            component: () => import('@/views/back/cae/FilterIndex.vue'),
          },
          {
            path: 'new-file',
            name: 'cae.labour-hazards.create',
            component: () => import('@/views/back/admin/labour-hazards/Edit.vue'),
          },
          {
            path: 'edit-file/:id',
            name: 'cae.labour-hazards.edit',
            component: () => import('@/views/back/admin/labour-hazards/Edit.vue'),
          },
          {
            path: 'view-icrea/:id',
            name: 'cae.view-icrea',
            component: () => import('@/views/back/cae/Icrea.vue'),
          },
          {
            path: 'alerts',
            name: 'admin.cae.view-alerts',
            component: () => import('@/views/back/cae/Alerts.vue'),
          },
        ],
      },
      { // Ondeuev zone
        path: 'memoir-exports',
        component: () => import('@/layouts/admin/RouterView.vue'),
        children: [
          { // List of links to export
            path: '/',
            name: 'memoir-exports.index',
            component: () => import('@/views/back/memoir/ExportList.vue'),
          },
          { // Profile
            path: 'profile',
            name: 'memoir-exports.profile',
            component: () => import('@/views/back/admin/users/Edit.vue'),
          },
        ],
      },
      { // Evaluator zone
        path: 'evaluator',
        component: () => import('@/layouts/admin/RouterView.vue'),
        children: [
          {
            path: 'dashboard',
            name: 'evaluator.dashboard',
            component: () => import('@/views/back/evaluator/Dashboard.vue'),
          },
          { // Profile
            path: 'evaluations/profile',
            name: 'evaluator.calls.evaluations.profile',
            component: () => import('@/views/back/admin/users/Edit.vue'),
          },
          { // Senior
            path: 'senior-calls/evaluations',
            name: 'evaluator.calls.evaluations.senior',
            component: () => import('@/views/back/evaluator/evaluations/ConvoEvaluation.vue'),
          },
          {
            path: 'senior-calls/evaluations/form-report',
            name: 'evaluator.calls.evaluations.senior.form-report',
            component: () => import('@/views/back/evaluator/evaluations/ConvoFormReport.vue'),
          },
          {
            path: 'senior-calls/evaluations/feedback-report',
            name: 'evaluator.calls.evaluations.senior.feedback-report',
            component: () => import('@/views/back/evaluator/evaluations/ConvoFeedbackReport.vue'),
          },
          { // Academia
            path: 'academia-calls/evaluations',
            name: 'evaluator.calls.evaluations',
            component: () => import('@/views/back/evaluator/evaluations/ConvoEvaluation.vue'),
          },
          {
            path: 'academia-calls/evaluations/form-report',
            name: 'evaluator.calls.evaluations.form-report',
            component: () => import('@/views/back/evaluator/evaluations/ConvoFormReport.vue'),
          },
          {
            path: 'academia-calls/evaluations/feedback-report',
            name: 'evaluator.calls.evaluations.feedback-report',
            component: () => import('@/views/back/evaluator/evaluations/ConvoFeedbackReport.vue'),
          },
          { // Promotion
            path: 'promotion-calls/evaluations/assign-peers',
            name: 'evaluator.calls.evaluations.assign-peers',
            component: () => import('@/views/back/evaluator/promotion/AssignPeers.vue'),
          },
          {
            path: 'promotion-calls/evaluations/edit-peers',
            name: 'evaluator.calls.evaluations.edit-peers',
            component: () => import('@/views/back/researcher/promotion-call/Index.vue'),
          },
          {
            path: 'promotion-calls/evaluations/add-peers',
            name: 'evaluator.calls.evaluations.add-peers',
            component: () => import('@/views/back/researcher/promotion-call/AddPeer.vue'),
          },
          {
            path: 'promotion-calls/evaluations/form-report',
            name: 'evaluator.calls.promotion.evaluations.form-report',
            component: () => import('@/views/back/evaluator/promotion/FormReport.vue'),
          },
          {
            path: 'promotion-calls/evaluations',
            name: 'evaluator.calls.promotion.evaluations',
            component: () => import('@/views/back/evaluator/promotion/ConvoEvaluation.vue'),
          },
          {
            path: 'promotion-calls/panel-meeting',
            name: 'evaluator.calls.promotion.panel-meeting',
            component: () => import('@/views/back/evaluator/promotion/ConvoEvaluation.vue'),
          },
          {
            path: 'promotion-calls/evaluate',
            name: 'evaluator.calls.promotion.evaluations.evaluate',
            component: () => import('@/views/back/evaluator/promotion/EvaluatorForm.vue'),
          },
        ],
      },
      { // University zone
        path: 'university',
        component: () => import('@/layouts/admin/RouterView.vue'),
        children: [
          {
            path: '/',
            name: 'university.dashboard',
            component: () => import('@/views/back/university/Dashboard.vue'),
          },
          {
            path: 'awardees/:id',
            name: 'university.awarded.show',
            component: () => import('@/views/back/university/awarded/Show.vue'),
          },
          {
            path: 'awardees/acceptance-document/:id',
            name: 'university.awarded.accpetnace-document',
            component: () => import('@/views/back/university/awarded/AcceptanceDocument.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]
